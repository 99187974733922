import { useEffect, useRef, useState } from "react";
import "./services.css";
import Web from "../../assets/site_layout.jpg";
import OurServices from "../../assets/our-services.png";
import Mobile from "../../assets/mobile-app.png";
import UXUI from "../../assets/UI-UX.png";
import Maintanence from "../../assets/Maintanance.png";
import Cloud from "../../assets/cloud.png";
import QA from "../../assets/QA engineers.png";
import Marketing from "../../assets/Marketing-consulting-2.png";

import { FaRegCheckCircle, FaAndroid, FaApple } from "react-icons/fa";
import { CgWebsite } from "react-icons/cg";
import { TbBrandReactNative, TbBrandFlutter, TbBugOff } from "react-icons/tb";
import { AiOutlineCloudServer, AiOutlineCloudSync } from "react-icons/ai";
import { CiCloudSun } from "react-icons/ci";
import Ecommerce from "../../assets/online-shopping.png";
import Progressive from "../../assets/loading-bar.png";
import TechnicalSupport from "../../assets/technical-support.png";
import WebBug from "../../assets/bug.png";
import MobileBug from "../../assets/bugs.png";
import CloudAutomation from "../../assets/computing-cloud.png";
import ManualTesting from "../../assets/manual-testing.png";
import AutomationTesting from "../../assets/automation-testing.png";
import ApiTesting from "../../assets/API-Testing.png";
import Imporve from "../../assets/improve.png";
import Modification from "../../assets/tool-and-utensils.png";
import useWindowSize from "../../hooks/useWindowSize";
import { Carousel } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import ServiceCard from "../../Components/ServiceCard/Service";

const ServicesScreen = () => {
  const [isVisible, setIsVisible] = useState(false);
  const missionContainerRef = useRef<any>(null);
  const { width } = useWindowSize();

  const isMobile = width < 641;

  useEffect(() => {
    const handleScroll = () => {
      const missionContainer = missionContainerRef.current;
      if (!missionContainer) {
        return;
      }

      const rect = missionContainer.getBoundingClientRect();

      const isCurrentlyVisible =
        rect.top < window.innerHeight && rect.bottom >= window.innerHeight / 2;
      setIsVisible(isCurrentlyVisible);
    };

    const handleFocus = () => {
      setIsVisible(true);
    };

    // Initial check
    handleScroll();

    // Attach scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Attach focus event listener
    const missionContainer = missionContainerRef.current;
    if (missionContainer) {
      missionContainer.addEventListener("focus", handleFocus);
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
      if (missionContainer) {
        missionContainer.removeEventListener("focus", handleFocus);
      }
    };
  }, []);

  const scrollToDiv = () => {
    const element = document.getElementById("services");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div
      ref={missionContainerRef}
      className={`service-main-container`}
      tabIndex={0} // Allow the div to receive focus
    >
      <div className="service-header-container">
        <img className="services-img" src={OurServices} alt="Our Services" />
        <div className="service-header-description">
          <h3>Infinite Services, Infinite Possibilities</h3>
          <div className="divider"></div>
          <p>
            At Infinitude Stack Inc., we are dedicated to delivering top-notch
            software solutions tailored to meet our clients' unique
            requirements. Specializing in custom software development, we
            leverage cutting-edge technology and innovative design principles to
            build scalable, high-performance solutions that drive business
            success. From initial concept to deployment, our team ensures
            exceptional results, whether it's enterprise software, mobile apps,
            or cloud-based platforms.
          </p>
          <p>
            Trusted by businesses worldwide, Infinitude Stack is your go-to
            partner for:
            <ul>
              <li>Custom software development</li>
              <li>Client-centric solutions tailored to your business needs</li>
              <li>Innovative technology integration</li>
              <li>Responsive and scalable design</li>
            </ul>
          </p>
          <button className="service-button" onClick={scrollToDiv}>
            OUR SERVICES
            <svg
              className="arrow-icon"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              viewBox="0 0 16 16"
            >
              <path d="M8 12.293l4.146-4.146a.5.5 0 0 0-.708-.708L8 11.793 4.562 7.44a.5.5 0 1 0-.708.708L8 12.293z" />
            </svg>
          </button>
        </div>
      </div>
      <div className="services" id="services">
        <div className="service-section">
          {isMobile && <img src={Web} alt={"Web Development"} />}
          <div className="service-description" id="Web-Development">
            <h3>Web Development</h3>
            <p>
              We specialize in building and customizing web and mobile
              applications using the latest technologies tailored to your
              business needs. Our solutions are designed to maximize your
              potential and drive success, enabling you to achieve exceptional
              growth and profitability.
            </p>
            <ul className="services-list">
              <li>Website Development</li>
              <li>Web App Development</li>
              <li>E-Commerce Web App Development</li>
            </ul>
          </div>
          {!isMobile && <img src={Web} alt={"Web Development"} />}
        </div>
        <div className="service-section" id="Mobile-App-Development">
          <img src={Mobile} alt={"Mobile App Development"} />

          <div className="service-description">
            <h3>Mobile App Development</h3>
            <p>
              We offer mobile application development that brings your business
              values right to your fingertips. Our solutions are designed to
              build and customize apps that have the potential to drive
              exceptional growth and profitability.
            </p>
            <ul className="services-list">
              <li>Android Application</li>
              <li>IOS Application</li>
              <li>Mobile Application (React Native, Flutter)</li>
            </ul>
          </div>
        </div>
        <div className="service-section" id="Cloud-Services">
          {isMobile && <img src={Cloud} alt={"Cloud Services"} />}
          <div className="service-description">
            <h3>Cloud Services</h3>
            <p>
              Our end-to-end cloud services accelerate your business growth by
              leveraging the power of cloud technology. Transform your
              operations and scale effortlessly with our comprehensive cloud
              solutions.
            </p>
            <ul className="services-list">
              <li>Cloud Migration</li>
              <li>Cloud Security</li>
              <li>CI/CD Integration</li>
              <li>Deployment</li>
              <li>Cloud Automation</li>
            </ul>
          </div>
          {!isMobile && <img src={Cloud} alt={"Cloud Services"} />}
        </div>
        <div className="service-section" id="UX-UI-Services">
          <img src={UXUI} alt={"UX/UI"} />

          <div className="service-description">
            <h3>UX/UI Services</h3>
            <p>
              Our UX/UI design services create intuitive and engaging user
              experiences that elevate your brand and drive user satisfaction.
              We specialize in crafting visually stunning and user-friendly
              interfaces that ensure your digital products not only look great
              but also deliver exceptional performance.
            </p>
          </div>
        </div>
        <div className="service-section" id="QA-Testing">
          {isMobile && <img src={QA} alt={"QA & Testing"} />}
          <div className="service-description">
            <h3>QA & Testing</h3>
            <p>
              Elevate customer insights and drive business success with our
              comprehensive quality testing services. We ensure top-notch
              performance through extensive test cases and robust automation
              scripts, delivering reliable and scalable solutions.
            </p>
            <ul className="services-list">
              <li>Manual Testing</li>
              <li>Automation Testing</li>
              <li>API Testing</li>
            </ul>
          </div>
          {!isMobile && <img src={QA} alt={"QA & Testing"} />}
        </div>
        <div className="service-section" id="Digital-Marketing-Consulting">
          <img src={Marketing} alt={"Digital Marketing Consulting"} />

          <div className="service-description">
            <h3>Digital Marketing Consulting</h3>
            <p>
              We provide a personalized digital marketing experience, guided by
              industry experts who truly understand your needs and ensure you
              receive top-tier service and exceptional results in the areas that
              matter most.
            </p>
            <ul className="services-list">
              <li>Strategic Planning</li>
              <li>Paid Advertising</li>
              <li>Email Marketing</li>
              <li>Social Media Strategy</li>
              <li>Analytics & Optimization</li>
            </ul>
          </div>
        </div>
        <div className="service-section" id="Support-Maintenance">
          {isMobile && <img src={Maintanence} alt={"Support & Maintenance"} />}

          <div className="service-description">
            <h3>Support & Maintenance</h3>
            <p>
              Maintenance Matters! Ensure optimal performance with our quality
              testing services, which include comprehensive test cases and
              advanced automation scripts. We focus on delivering reliable
              solutions that maintain and enhance your application's quality.
            </p>
            <ul className="services-list">
              <li>Bug Solving</li>
              <li>Performance Improvements</li>
              <li>Modification & Technical Enhancement</li>
            </ul>
          </div>
          {!isMobile && <img src={Maintanence} alt={"Support & Maintenance"} />}
        </div>
      </div>
    </div>
  );
};

export default ServicesScreen;

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Helmet } from "react-helmet";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Helmet>
      <title>Infinitude Stack</title>
      <meta
        name="description"
        content="Infinitude Stack Inc. is a leading software solution provider specializing in innovative technology and unique design concepts. We leverage our expertise to create cutting-edge software solutions that drive success and deliver exceptional results tailored to your needs."
      />
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "SoftwareCompany",
          name: "Infinitude Stack Inc.",
          url: "https://www.infinitudestack.com",
          description:
            "A premier software development company specializing in custom software solutions tailored to client needs.",
          sameAs: ["https://www.linkedin.com/company/infinitudestack"],
        })}
      </script>
    </Helmet>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
